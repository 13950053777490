import $ from 'jquery';
//declare var Swiper: any;

$(function () {
  let currentHeight = 0;
  //header
  $(window).on('load orientationchange resize', function () { //ページのロード又はリサイズ時にイベント発生
    const hsize = $('.l-fixedTop--header').height() || 0; //ヘッダーの高さを定義
    $('.p-headermodal__step , .p-menu').css('height', window.innerHeight - hsize); //モーダルのheight取得(windowサイズからヘッダーの高さを引いたもの)
  });
  // 資料請求・見学予約リスト モーダル表示
  $('.js-modal--stepButton').on('click', function () {
    // モーダルが閉じているとき
    if ($(this).hasClass('js-is-close')) {
      const posi = $(window).scrollTop() || 0; //モーダル表示中、背景がスクロールされないよう固定
      currentHeight = posi; // モーダルを閉じる時の高さを保持
      $('.js-modal--step').fadeIn(); //モーダル表示
      $('.js-headermodal__content').scrollTop(0); //再度モーダルを開いた際にモーダル画面のスクロール位置が最上部になるよう設定
      $('html').css({ //モーダルを表示させた位置で背景を固定
        width: '100%',
        position: 'fixed',
        top: -1 * posi
      });
      $(this).removeClass('js-is-close');
      $(this).addClass('js-is-open');
      return false;
      // モーダルが開いているとき
    } else {
      $('.js-modal--step').fadeOut(); //モーダル非表示
      $('html').attr('style', ''); //背景スクロール固定解除
      $(this).removeClass('js-is-open');
      $(this).addClass('js-is-close');
      $('html, body').prop({ scrollTop: currentHeight }); //位置を戻す
      return false;
    }
  });
  // 資料請求・見学予約リスト モーダル 「閉じる」ボタンタップ時
  $('.js-modal--stepClose').on('click', function () {
    $('.js-modal--step').fadeOut(); //モーダル非表示
    $('html').attr('style', ''); //背景スクロール固定解除
    $('html, body').prop({ scrollTop: currentHeight }); //位置を固定
    $('.js-modal--stepButton').removeClass('js-is-open'); // 資料請求・見学予約リスト モーダルのclassをリセット
    $('.js-modal--stepButton').addClass('js-is-close'); // 資料請求・見学予約リスト モーダルのclassをリセット
    return false;
  });
  // 資料請求・見学予約リスト モーダル非表示
  $(document).on('click touchend', function (e) { //モーダル及びアラート画面以外のドキュメントをタップでイベント発生
    if (!$(e.target).closest('.js-modal--stepButton , .js-headermodal__content , .js-alert').length) {
      $('.js-modal--step').fadeOut();
      $('.js-modal--stepButton').removeClass('js-is-open'); // 資料請求・見学予約リスト モーダルのclassをリセット
      $('.js-modal--stepButton').addClass('js-is-close'); // 資料請求・見学予約リスト モーダルのclassをリセット
    }
  });
  // 資料請求・見学予約リスト 削除アラート表示
  $('.js-table--homeList__deleteButton').on('click', function () { //リストの削除「×」ボタンタップでイベント発生
    $('.js-alert').fadeIn();
    return false;
  });
  // 資料請求・見学予約リスト 削除アラート非表示
  $('.js-alert').on('click touchend', function (e) { //アラート画面以外のドキュメントをタップでイベント発生
    if (!$(e.target).closest('.js-alert__item').length) {
      $('.js-alert').fadeOut();
    }
  });
  $('.js-alert__itemClose , .js-alert__itemButtonNo').on('click', function () { //アラート「×」ボタン及び「いいえ」ボタンタップでイベント発生
    $('.js-alert').fadeOut();
    return false;
  });
  // 資料請求・見学予約リスト 削除アラート「はい」タップ後 該当のリストを削除
  $('.js-alert__itemButtonYes').on('click', function () { //削除アラート「はい」タップ後イベント発生
    $.when(
      $('.js-alert').fadeOut()　//削除アラート非表示
    ).done(function () {
      $('.hoge').fadeOut();　//該当のリストを削除
    });
    return false;
  });
  // メニュー
  const txt = $('.js-header__menuText').html(); //メニュー ボタンの見出し（「メニュー」「閉じる」）
  $('.js-header__menu').on('click', function () { //メニューボタンタップでイベント発生
    $('.js-modal--stepButton').removeClass('js-is-open'); // 資料請求・見学予約リスト モーダルのclassをリセット
    $('.js-modal--stepButton').addClass('js-is-close'); // 資料請求・見学予約リスト モーダルのclassをリセット
    if ($(this).hasClass('active')) { //メニュー 閉じる
      $(this).removeClass('active');
      $('.js-header__menu').removeClass('open');
      $('.js-menu').removeClass('open');
      $('.js-menu__background').removeClass('open'); //メニュー 白背景非表示
      $('.js-menu__overlay').removeClass('open'); //メニュー 黒背景非表示
      $('.js-header__menuText').html(
        txt.replace('閉じる', 'メニュー') //メニュー ボタンの見出しを「閉じる」から「メニュー」に変更
      );
      $('html').attr('style', ''); //背景スクロール固定解除
      $('html, body').prop({ scrollTop: currentHeight }); //位置を固定
    } else {
      const posi = $(window).scrollTop() || 0; //モーダル表示中、背景がスクロールされないよう固定
      currentHeight = posi; // モーダルを閉じる時の高さを保持
      $(this).addClass('active'); //メニュー 開く
      $('.js-header__menu').addClass('open');
      $('.js-menu').addClass('open');
      $('.js-menu__background').addClass('open'); //メニュー 白背景表示
      $('.js-menu__overlay').addClass('open'); //メニュー 黒背景表示
      $('.js-menu').scrollTop(0); //スクロール位置を最上部に設定
      $('.js-menu .js-gnav--accordion').removeClass('active'); //開いたアコーディオンを閉じた状態で表示
      $('.js-menu .js-gnav--accordion').next('.js-gnav--accordion__item').css('display', 'none'); //開いたアコーディオンを閉じた状態で表示
      $('.js-header__menuText').html(
        txt.replace('メニュー', '閉じる') //メニュー ボタンの見出しを「メニュー」から「閉じる」に変更
      );
      $('html').css({ //表示させた位置で背景を固定
        width: '100%',
        position: 'fixed',
        top: -1 * currentHeight
      });
    }
  });
  $('.js-menu__overlay').on('click', function () { //メニュー 黒背景タップでメニュー閉じる
    const posi = $(window).scrollTop() || 0;
    if ($(this).hasClass('open')) {
      $(this).removeClass('open');
      $('.js-header__menu').removeClass('active');
      $('.js-header__menu').removeClass('open');
      $('.js-menu').removeClass('open');
      $('.js-menu__background').removeClass('open');
      $('html').attr('style', ''); //背景スクロール固定解除
      $('html, body').prop({ scrollTop: posi }); //位置を固定
    }
  });
  //gnav
  $('.js-gnav--accordion').on('click', function () {
    $(this).next('.js-gnav--accordion__item').slideToggle('slow');
    $(this).toggleClass('active');
    return false;
  });

  $('.pg_floating').on('click', function() {
    $(this).toggleClass('is-open');
  });
});